<template>
  <div></div>
</template>

<script>
import Vue from "vue";
// const axios = require("@/store/axios.config.js").default;
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
export default {
  data() {
    return {
      tokens: ""
    };
  },
  mounted() {
    if (this.$route.query.token) {
      if (localStorage.getItem("access_token")) {
        localStorage.removeItem("access_token");
      }
      localStorage.setItem("access_token", this.$route.query.token);
      this.$router.push({ name: "dashboard" });
    } else {
      this.$router.push({ name: "signin" });
    }
  }
};
</script>

<style></style>
